<template>
  <page no-header height="full">
    <search-header placeholder="大家都在搜什么呢" link="/" class="md:hidden bg-white" slot="fixed-top" />
    <div class="bg-white">
      <div class="px-4">
        <div class="relative md:pt-1/3 pt-1/2">
          <cube-slide ref="slide" :data="TestData.slides" @change="changePage" class=" absolute inset-0 rounded">
            <cube-slide-item v-for="(item, index) in TestData.slides" :key="index" @click.native="clickHandler(item, index)">
              <img :src="item.image" class="object-cover w-full"/>
            </cube-slide-item>
          </cube-slide>
        </div>
      </div>
      <div class="p-4 grid grid-cols-4 gap-1 sm:grid-cols-5 md:grid-cols-8">
        <icon-nav 
          v-for="(item,index) in TestData.menu" 
          :key="index" 
          :label="item.label"  
          :url='`/category/${item.id}`' 
          :img-icon="item.icon"
        ></icon-nav>
        <icon-nav  label="更多" color="blue"  icon="icon-all"></icon-nav>
      </div>
    </div>
    
    <title-bar title="热门推荐" class="mt-2" moreLink="/" no-bg></title-bar>
    <divider class="px-4 bg-white md:mb-4"></divider>
    <list :empty="!TestData.videoList.length" >
      <div class="grid gap-2 md:gap-4  grid-cols-2 md:grid-cols-4 lg:grid-cols-6 md:px-4">
        <grid-card
          v-for="item in TestData.videoList" 
          :key="item.id" 
          :image="item.image" 
          :title="item.title"
          img-ratio="16/15"
          tag="自营"
          note="包邮 退货包运费"
          price="3921"
          count="已售3929"
          :url="`/goods-detail/${item.id}`"
        ></grid-card>
      </div>
    </list>
    
  </page>
</template>

<script>
import { SearchHeader, IconNav, TitleBar, MediaItem, IconText, List } from "../../components";
import {mapGetters} from 'vuex'
export default {
  components: {
    SearchHeader,
    IconNav,
    TitleBar,
    MediaItem,
    IconText,
    List
  },
  data() {
    return {
      curTab: '精选视频',
    };
  },
  computed: {
    ...mapGetters([
      'TestData'
    ])
  },
  methods: {
    changePage(current) {
      // console.log('当前轮播图序号为:' + current)
    },
    clickHandler(item, index) {
      console.log(item, index)
    },

    tabChange(cur) {
      console.log(cur);
      this.curTab = cur;
    }
  },
  created() {}
};
</script>


<style lang="stylus">

</style>